const initialState = {
  loading: false,
  totalSupply: 0,
  publicPrice: 0,
  freeMinted: 0,
  MaxPerTX: 0,
  publicActive:1,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        publicPrice: action.payload.publicPrice,
        MaxPerTX: action.payload.MaxPerTX,
        publicActive : action.payload.publicActive,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
