// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let publicPrice = await store
         .getState()
         .blockchain.smartContract.methods.publicPrice()
         .call();
     let MaxPerTX = await store
         .getState()
         .blockchain.smartContract.methods.MaxPerTX()
         .call();
      let publicActive = await store
         .getState()
         .blockchain.smartContract.methods.publicActive()
         .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          publicPrice,
          MaxPerTX,
          publicActive,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
